a {
	color: var(--joy-palette-main-primary) !important;
}

.layout {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}
.layout-main {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.full-width {
	width: 100%;
}
.center-content {
	display: flex;
	justify-content: center;
}
.rounded-block {
	border-radius: 20px;
}
.row-limited-height {
	height: 50px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.leaflet-control-attribution {
	margin-right: 15px !important;
}
.leaflet-control-zoom {
	margin-left: 20px !important;
	margin-top: 20px !important;
}
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
	color: var(--joy-palette-main-primary) !important;
}
.leaflet-popup-content {
	margin: 0 !important;
}
.leaflet-popup-content-wrapper {
	padding-left: 0 !important;
	/* padding-top: 0 !important; */
	border-radius: 30px !important;
	padding-right: 0 !important;
	box-shadow: var(--joy-shadow-sm) !important;
	overflow: hidden !important;
}
.leaflet-popup-content p {
	margin: 0 !important;
}
.leaflet-popup-close-button {
	display: none !important;
}
.leaflet-left {
	z-index: 800 !important;
}
/* .verticalCarousel{
} */
.slick-vertical {
	width: 100% !important;
	flex-grow: 1 !important;
}
.swiper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
}

.swiper-slide {
	display: flex !important;
	height: auto !important;
	justify-content: center;
	align-items: center;
}
#react-tiny-popover-container {
	z-index: 100;
}
