.ql-formats {
	display: flex;
}

.ql-toolbar {
	border-radius: 20px 20px 0 0;
	background: var(--joy-palette-main-background);
	display: flex;
	flex-direction: column;
}

/* .ql-snow.ql-toolbar button:hover {
  color: rgb(26, 255, 0) !important;
} */

/* button.ql-image:hover {
  background-color: aqua;
  stroke: chartreuse;
} */

/* blog-creator path .ql-snow .ql-stroke {
  stroke: #ff0 !important; 
} */

/* button:hover .ql-stroke {
  stroke: #ff0 !important; 
} */

/* Стиль для контейнера с подсказкой */
.tooltip {
	position: absolute;
	background-color: #333;
	color: #fff;
	padding: 5px;
	border-radius: 4px;
	font-size: 12px;
	white-space: nowrap;
	z-index: 1000;
	opacity: 0;
	pointer-events: none;
	transition: opacity 0.3s;
}

/* Показываем подсказку при наведении */
.toolbar-button:hover .tooltip {
	opacity: 1;
}
